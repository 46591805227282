<template>

	<Teleport to="#popups">
		<div :id="id ? id : ''" class="overlay-wrapper" :class="{hidden: !isActive, noOverlay: currNoOverlay}" @click.self="clickOverlay" :style="zIndex ? 'z-index: '+zIndex : ''">
			<div class="bottom-sheet-container">
				<div class="bottom-sheet" ref="bottom-sheet" :style="forceWidth ? 'width: '+forceWidth : ''">
					<div class="bottom-sheet-measurer" ref="bottom-sheet-measurer" :class="{activeScroll: activeScrollbar}">
						<div class="bottom-sheet-content" ref="bottom-sheet-content">
							<slot></slot>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Teleport>

</template>

<script>
import {useValuesStore} from "@/shared/stores";

export default {
	setup() {
		const valuesStore = useValuesStore();
		return {valuesStore};
	},
	name: "AppBottomSheet",
	emits: ["toggling-popup", "click-overlay"],
	data() {
		return {
			isActive: this.active,
			activeScrollbar: false,
			xPos: 0,
			yPos: 0,
			currNoOverlay: this.noOverlay,
            scrollElements: [],
			scrollElementsMap: {},
			escListener: null
		}
	},
	props: {
		id: {
			type: String,
			default: null
		},
		noOverlay: {
			type: Boolean,
			default: false
		},
		active: {
			type: Boolean,
			default: false
		},
		forceWidth: {
			type: String,
			default: null,
			validator(value) {
				return value === null || value.includes('px') || value.includes('%');
			}
		},
		undismissable: {
			type: Boolean,
			default: false
		},
		zIndex: {
			type: Number,
			default: null
		}
	},
	methods: {
		clickOverlay() {
			this.$emit('click-overlay');
			if(!this.undismissable) {
				this.toggleStatus(false);
			}
		},
		toggleStatus(newStatus = !this.isActive) {
			this.isActive = newStatus;
			this.updateScroll();
			this.$emit("toggling-popup", this.isActive);
		},
        updateScroll() {
            if(!this.currNoOverlay && this.isActive) {
                this.findScrollableElements();
                this.preventScrollOnElements();
            } else {
                this.restoreScrollOnElements();
            }
        },
        findScrollableElements() {
            const scrollables = [];
            const elements = document.querySelectorAll('*');

            for (let elem of elements) {
                if ((elem.scrollHeight > elem.clientHeight && window.getComputedStyle(elem).overflowY === 'auto') ||
                    (elem.scrollWidth > elem.clientWidth && window.getComputedStyle(elem).overflowX === 'auto')) {
					elem.id = elem.id ? elem.id : Math.random().toString(36).substring(2, 15);
                    scrollables.push(elem);
                }
            }

            if(!scrollables.includes(document.documentElement)) {
				let computedStyle = window.getComputedStyle(document.documentElement);
				if(computedStyle.overflowY === 'auto' || computedStyle.overflowX === 'auto') {
					scrollables.push(document.documentElement);
				}
            }
            if(!scrollables.includes(document.body)) {
				let computedStyle = window.getComputedStyle(document.body);
				if(computedStyle.overflowY === 'auto' || computedStyle.overflowX === 'auto') {
					scrollables.push(document.body);
				}
            }
            this.scrollElements = scrollables;
        },
        preventScrollOnElements() {
            this.scrollElements.forEach(el => {
				this.scrollElementsMap[el.id] = el.style.overflow ? el.style.overflow : 'auto';
                el.style.overflow = 'hidden';
            });
        },
        restoreScrollOnElements() {
            this.scrollElements.forEach(el => {
                if(this.scrollElementsMap[el.id]) {
					el.style.overflow = this.scrollElementsMap[el.id];
				}
            });
        },
	},
	created() {
		if(!document.getElementById("popups")) {
			let popups = document.createElement("div");
			popups.id = "popups";
			document.body.appendChild(popups);
		}
	},
	mounted() {
		setTimeout(() => {
			if(this.isActive) {
				this.activeScrollbar = true;
			}
		}, 360);

		this.escListener = (e) => {
			if (e.keyCode === 27) {
				this.clickOverlay();
			}
		}
		document.addEventListener('keyup', this.escListener);
	},
	beforeUnmount() {
		document.removeEventListener('keyup', this.escListener);
	},
	watch: {
		active(newStatus) {
			this.toggleStatus(newStatus);
		},
		isActive(newStatus) {
			if(newStatus) {
				setTimeout(() => {
					if(this.isActive) {
						this.activeScrollbar = true;
					}
				}, 360);
			} else {
				this.activeScrollbar = false;
			}
		},
		forceWidth() {
			this.$refs["bottom-sheet"].style.width = this.forceWidth;
		},
		noOverlay() {
			this.currNoOverlay = this.noOverlay;
            this.updateScroll();
		},
		"valuesStore.forceCloseAllBottomSheets"(newStatus) {
			if(newStatus) {
				this.toggleStatus(false);
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.overlay-wrapper {
	z-index: 1000;
	@include centered;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.75);
	visibility: visible;
	transition: visibility 0.3s $transition, background-color .3s $transition, opacity .3s $transition;

	.bottom-sheet-container {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		width: 100%;
		height: 100%;
		transition: opacity .2s $transition .1s, transform .2s $transition .1s;
		opacity: 1;
		transform: translateY(0);
		pointer-events: none;

		.bottom-sheet {
			width: 100%;
			background-color: $bg_0;
			max-height: calc(95% - 30px);
			padding: 20px;
			border-radius: 20px 20px 0 0;
			pointer-events: all;
			box-shadow: $box_shadow_inset;
			transition: height 0.3s ease;
			position: relative;

			.bottom-sheet-measurer {
				overflow-y: hidden;
				overflow-x: hidden;
				@include hideScrollbar();
				height: 100%;

				.bottom-sheet-content {
					overflow-y: auto;
					height: auto;

				}

				&.activeScroll {
					overflow-y: auto;
				}

			}

		}
	}

	&.hidden {
		visibility: hidden;
		background-color: transparent;
		opacity: 0;
		pointer-events: none;

		.bottom-sheet-container {
			opacity: 0;
			transform: translateY(calc(100vh + 200px));
			transition: opacity .2s $transition, transform .2s $transition;
		}
	}

	&.noOverlay {
		background-color: transparent;
		pointer-events: none;

		.bottom-sheet-container .bottom-sheet {
			box-shadow: 0 -5px 80px #021d3b24;
		}
	}

}
</style>